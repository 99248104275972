import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import {
  addReschedulingPolicy,
  deleteReschedulingPolicy,
  updateReschedulingPolicy,
} from "../../../redux/Account/ShopDetails/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ReschedulePolicy = () => {
  const getReseduleApiData = useSelector(
    (state) => state?.account?.shopDetailsData?.reschedulePolicy
  );
  const { control, handleSubmit, reset, setValue, errors } = useForm();
  const [resecheduleData, setRescheduleData] = useState();
  const [openRescheduleModel, setOpenRescheduleModel] = useState(false);
  const [editRescheduleModal, setEditRescheduleModal] = useState(false);
  const [allowApiCallReschedule, setAllowApiCallReschedule] = useState(true);
  const [selectedValue, setSelectedValue] = useState("");
  const [typeOfCancel, setTypeOfCancel] = useState("");
  const [typeOfAmount, setTypeOfAmount] = useState("");
  const [numOfDays, setNumOfDays] = useState("");
  const [amountNum, setAmountNum] = useState("");
  const [rescheduleFlag, setRescheduleFlag] = useState(false);
  const [Ids, setIds] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [openDeleteConformModal, setOpenDeleteConformModal] = useState(false);
  const [deleteRescheduledPolicy, setDeleteRescheduledPolicy] = useState(false);
  const [resultData, setResultData] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setSelectedValue("");
    setTypeOfCancel("");
    setTypeOfAmount("");
    setValue("cancelUntill", "");
    setNumOfDays("");
    setValue("amount", "");
    setAmountNum("");
  };

  const handleRescheduleModelOpen = () => {
    setOpenRescheduleModel(true);
  };

  const handleCloseRescheduleModel = () => {
    setOpenRescheduleModel(false);
    setSelectedValue("");
    setTypeOfCancel("");
    setTypeOfAmount("");
    setNumOfDays("");
    setAmountNum("");
  };

  const onAddReschedulingPolicy = async (values) => {
    try {
      const payload = {
        amount: amountNum,
        amountType: "fixed",
        rescheduleUntill: numOfDays,
        rescheduleUntillType: selectedValue,
      };

      let result;
      let resultArr = [];
      setShowLoader(true);
      if (editRescheduleModal && rescheduleFlag) {
        result = await dispatch(updateReschedulingPolicy(payload, Ids));
        if (result) {
          resultArr.push(result);
          setResultData(resultArr);
        }
      } else {
        result = await dispatch(addReschedulingPolicy(payload));
        if (result) {
          resultArr.push(result);
          setResultData(resultArr);
          setEditRescheduleModal(false);
          setOpenRescheduleModel(false);
        } else {
          handleCloseRescheduleModel(false);
        }
        reset();
      }

      if (result) {
        setSelectedValue("");
        setTypeOfCancel("");
        setTypeOfAmount("");
        setValue("cancelUntill", "");
        setNumOfDays(0);
        setValue("amount", "");
        setAmountNum("");
        setOpenRescheduleModel(false);
        setEditRescheduleModal(false);
      }
    } catch (error) {
      console.error("Error adding/updating rescheduling policy:", error);
      toast.error(
        "Failed to add/update rescheduling policy. Please try again."
      );
    } finally {
      setShowLoader(false);
    }
  };

  const getCancelReschedulepolicy = async () => {
    setShowLoader(true);
    try {
      let result = await dispatch(deleteReschedulingPolicy(Ids));
      if (result) {
        setResultData(result);
      }
      setShowLoader(false);
      handleCloseModal();
    } catch (error) {
      console.log("error", error);
    }
    handleCloseRescheduleModel(false);
  };
  const EditManageRescheduling = (item) => {
    setIds(item?._id);
    setAmountNum(item?.amount);
    setNumOfDays(item?.rescheduleUntill);
    setTypeOfCancel(item?.rescheduleUntillType);
    setSelectedValue(item?.rescheduleUntillType);
  };

  return (
    <>
      <Grid
        container
        sx={{ boxShadow: 2, bgcolor: "white" }}
        justifyContent={"space-between"}
        padding={"24px"}
        borderRadius={"10px"}
        marginTop={"32px"}
        paddingRight={"20px"}
      >
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={3}
          lg={3}
          xl={3}
          paddingRight={"10px"}
          style={{ height: "max-content" }}
        >
          <Typography variant="h5" style={{ fontWeight: 400 }}>
            Rescheduling Policy
          </Typography>
          <Typography
            variant="body"
            component="div"
            style={{ marginTop: "20px" }}
          >
            To minimize your last-minute reschedules, you have the option to
            implement a reschedule policy for customers. You can set a specific
            minimum notice period when customers need to reschedule to avoid
            incurring a fee.
          </Typography>
          <Typography variant="body" style={{ marginTop: "20px" }}>
            While we recommend that customers try to avoid last-minute
            reschedules, implementing a policy is completely optional. You can
            continue to use Apex Mechanic without imposing any charges on
            customers for reschedules.
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={8.5}
          lg={8.5}
          xl={8.5}
          direction="row"
          justifyContent="end"
          padding="10px"
          className="cancel-policy-listing-grid"
        >
          <Grid item className="fit-height-style">
            <Stack justifyContent="end" spacing={2} className="align-item-end">
              <Button
                className="btn-text-size-manage"
                variant="contained"
                color="secondary"
                onClick={() => {
                  setEditRescheduleModal(false);
                  handleRescheduleModelOpen();
                }}
              >
                ADD RESCHEDULE
              </Button>

              <Box>
                <Modal
                  open={openRescheduleModel}
                  onClose={handleCloseRescheduleModel}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box sx={{ ...style, width: "720px" }}>
                    <AppBar variant="elevation" color="secondary" 
                    sx={{
                      height: "64px",
                      paddingRight: "0px !important",
                    }}>
                      <Toolbar variant="regular">
                        <Typography
                          variant="body1"
                          gutterBottom={false}
                          width={"98%"}
                          color={"white"}
                          fontSize={"20px"}
                          lineHeight={"32px"}
                          fontWeight={500}
                        >
                          {editRescheduleModal
                            ? "Edit Rescheduling Policy"
                            : "Add New Rescheduling Policy"}
                        </Typography>
                        <Stack direction={"row"} spacing={0}>
                          <div
                            onClick={() => {
                              handleCloseRescheduleModel();
                              setSelectedValue("");
                              setTypeOfCancel("");
                              setTypeOfAmount("");
                              // setValue("cancelUntill", "");
                              setNumOfDays("");

                              // setValue("amount", "");
                              setAmountNum("");
                            }}
                            style={{ cursor: "pointer",height:'32px' }}
                            >
                              <CloseIcon style={{ fontSize: 32 }} />
                          </div>
                        </Stack>
                      </Toolbar>
                    </AppBar>
                    <Container
                      gap={2}
                      disableGutters
                      style={{
                        marginTop: "60px",
                        paddingRight: "14px",
                      }}
                    >
                      <form onSubmit={handleSubmit(onAddReschedulingPolicy)}>
                        <Grid container maxHeight={"75vh"}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            xl={12}
                            sm={12}
                            lg={12}
                            style={{ marginTop: "20px" }}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Customer’s Can Cancel Until...
                            </Typography>
                            <Stack
                              direction={"row"}
                              spacing={2}
                              rowGap={2}
                              marginTop={"20px"}
                            >
                              <Grid item xs={6} md={6} xl={6} sm={6} lg={6}>
                                <Controller
                                  name="rescheduleUntill"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      variant="outlined"
                                      size="medium"
                                      state="enabled"
                                      fullWidth
                                      required
                                      // type="number"
                                      // error={!!errors.rescheduleUntill}
                                      // helperText={
                                      //   errors.rescheduleUntill
                                      //     ? errors.rescheduleUntill.message
                                      //     : ""
                                      // }
                                      onChange={(event) => {
                                        setAllowApiCallReschedule(true);
                                        const enteredValue = event.target.value;
                                        // Check if enteredValue is a non-negative number
                                        if (
                                          /^\d+$/.test(enteredValue) ||
                                          enteredValue === ""
                                        ) {
                                          setNumOfDays(enteredValue);
                                        }
                                      }}
                                      value={numOfDays}
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item xs={6} md={6} xl={6} sm={6} lg={6}>
                                <FormControl
                                  sx={{ m: 1, minWidth: 120 }}
                                  style={{ margin: 0, width: "100%" }}
                                  // {...field}
                                >
                                  <Select
                                    value={selectedValue}
                                    onChange={(event) => {
                                      setSelectedValue(event.target.value);
                                      setAllowApiCallReschedule(true);
                                    }}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    placeholder="type"
                                  >
                                    <MenuItem value={"days"}>Days</MenuItem>
                                    <MenuItem value={"hours"}>Hours</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Stack>
                          </Grid>

                          <Grid item xs={12} md={6} xl={12} sm={12} lg={12}>
                            <Stack
                              direction={"column"}
                              spacing={2}
                              rowGap={2}
                              marginTop={"20px"}
                            >
                              <Stack gap={2}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                  }}
                                >
                                  Amount
                                  <span style={{ color: "#D32F2F" }}>*</span>
                                </Typography>

                                <TextField
                                  variant="outlined"
                                  size="medium"
                                  state="enabled"
                                  fullWidth
                                  required
                                  type="number"
                                  // error={!!errors.amount}
                                  // helperText={
                                  //   errors.amount ? errors.amount.message : ""
                                  // }
                                  value={amountNum}
                                  onChange={(event) => {
                                    setAllowApiCallReschedule(true);
                                    const inputValue = event.target.value;
                                    // Check if the input is a non-negative number
                                    if (/^\d*\.?\d*$/.test(inputValue)) {
                                      setAmountNum(inputValue);
                                    }
                                  }}
                                  // {...field}
                                />
                                {/* )}
                                  /> */}
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6} xl={12} sm={12} lg={12}>
                            <Stack direction={"column"} spacing={1}>
                              <Stack>
                                <Typography
                                  variant="body1"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    marginTop: "20px",
                                    backgroundColor: "#F0F0F0",
                                    padding: "10px",
                                  }}
                                >
                                  {numOfDays ? (
                                    <>
                                      Customers will have until {numOfDays}{" "}
                                      {selectedValue + " "}
                                      to reschedule an appointment. Should they
                                      reschedule after this allocated time , a
                                      charge of ${amountNum} will be applied to
                                      the card on file and remitted to you.{" "}
                                    </>
                                  ) : (
                                    `Customers will have until number of day or hours to reschedule an appointment. Should they reschedule after this allocated time or fail to show up for the appointment, a charge of amount will be applied to the card on file and remitted to you.`
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>

                          <Grid
                            container
                            justifyContent={"end"}
                            spacing={2}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Stack
                              style={{
                                marginTop: "10px",
                                marginLeft: "18px",
                              }}
                            >
                              {editRescheduleModal && (
                                <Button
                                  className="btn-text-size-manage"
                                  variant="contained"
                                  color="error"
                                  onClick={() => {
                                    // setDeleteForTermsAndWarrenty(false);
                                    setOpenDeleteConformModal(true);
                                    setDeleteRescheduledPolicy(true);
                                  }}
                                >
                                  Delete
                                </Button>
                              )}
                            </Stack>
                            <Grid item spacing={2}>
                              <Stack spacing={2} direction="row">
                                <Button
                                  className="btn-text-size-manage"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    // handleClose();
                                    handleCloseRescheduleModel();
                                    setSelectedValue("");
                                    setTypeOfCancel("");
                                    setTypeOfAmount("");
                                    setValue("cancelUntill", "");
                                    setNumOfDays("");

                                    setValue("amount", "");
                                    setAmountNum("");
                                  }}
                                >
                                  Cancel{" "}
                                </Button>
                                <Button
                                  className="btn-text-size-manage"
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  disabled={!allowApiCallReschedule}
                                  onClick={() => {
                                    if (editRescheduleModal) {
                                      setRescheduleFlag(true);
                                      setEditRescheduleModal(true);
                                    } else {
                                      setRescheduleFlag(false);
                                      setEditRescheduleModal(false);
                                    }
                                  }}
                                >
                                  SAVE
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </Container>
                  </Box>
                </Modal>
              </Box>
            </Stack>
          </Grid>

          {Array.isArray(getReseduleApiData) &&
            resultData == null &&
            (getReseduleApiData.length > 0
              ? getReseduleApiData.map((item, index) => (
                  <React.Fragment key={item?._id}>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center"
                      paddingTop={"32px"}
                    >
                      <Grid item xs={6} sm={6} md={6} xl={5}>
                        {item?.rescheduleUntill && (
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {item?.rescheduleUntill}{" "}
                            {item?.rescheduleUntillType} until Appointment
                          </Typography>
                        )}

                        {item?.amount && (
                          <Typography variant="body" sx={{ marginTop: "15px" }}>
                            Amount: ${item?.amount}.00
                            {/* Amount: $
                            {item?.amount}.00 */}
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        item
                        spacing={2}
                        direction={"row"}
                        alignItems={"start"}
                        style={{ display: "contents" }}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            // className="btn-text-size-manage"
                            variant="contained"
                            size="medium"
                            style={{ marginRight: "10px" }}
                            // onClick={handleOpen}
                            onClick={() => {
                              EditManageRescheduling(item);
                              // editCancelPolicy(item);
                              setEditRescheduleModal(true);
                              // handleOpen();
                              handleRescheduleModelOpen();
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            // className="btn-text-size-manage"
                            variant="contained"
                            size="medium"
                            color="error"
                            onClick={() => {
                              setOpenDeleteConformModal(true);
                              setIds(item._id);
                              // setDeleteForTermsAndWarrenty(false);
                              setDeleteRescheduledPolicy(true);
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
              : null)}

          {resultData &&
            (Array.isArray(resultData)
              ? resultData.map((item, index) => (
                  <React.Fragment key={item?._id}>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center"
                      paddingTop={"32px"}
                    >
                      <Grid item xs={6} sm={6} md={6} xl={5}>
                        {item?.rescheduleUntill && (
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {item?.rescheduleUntill}{" "}
                            {item?.rescheduleUntillType} until Appointment
                          </Typography>
                        )}

                        {item?.amount && (
                          <Typography variant="body" sx={{ marginTop: "15px" }}>
                            Amount: ${item?.amount}.00
                            {/* Amount: $
                            {item?.amount}.00 */}
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        item
                        spacing={2}
                        direction={"row"}
                        alignItems={"start"}
                        style={{ display: "contents" }}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            // className="btn-text-size-manage"
                            variant="contained"
                            size="medium"
                            style={{ marginRight: "10px" }}
                            // onClick={handleOpen}
                            onClick={() => {
                              EditManageRescheduling(item);
                              // editCancelPolicy(item);
                              setEditRescheduleModal(true);
                              // handleOpen();
                              handleRescheduleModelOpen();
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            // className="btn-text-size-manage"
                            variant="contained"
                            size="medium"
                            color="error"
                            onClick={() => {
                              setOpenDeleteConformModal(true);
                              setIds(item._id);
                              // setDeleteForTermsAndWarrenty(false);
                              setDeleteRescheduledPolicy(true);
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
              : null)}
        </Grid>
      </Grid>

      <Dialog
        open={openDeleteConformModal}
        onClose={() => setOpenDeleteConformModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Rescheduling Policy ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              setOpenDeleteConformModal(false);
              // handleTermsClose();
              // setDeleteForTermsAndWarrenty(false);
              setDeleteRescheduledPolicy(false);
            }}
          >
            No
          </Button>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              setOpenDeleteConformModal(false);

              if (deleteRescheduledPolicy) {
                getCancelReschedulepolicy();
              }
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReschedulePolicy;
