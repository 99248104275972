import { useTheme } from "@emotion/react";
import { Edit, InfoOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ESTIMATE_INVOICE_DRAFT } from "../../../App";
import FormatNumber, { FormatNumberForKm, FormatTotalNumbers } from "../../../components/FormatNumber/FormatNumber";
import { deleteInvoice, invoiceDetails } from "../../../redux/Invoice/action";
import { formatPhoneNumber } from "../../Common/FormatPhoneNumber";
import Loader from "../../Common/Loader";
import { customisePartName } from "../../../utility/helpers/displayHelper";

function createData(no, disc, quantity, cost, amount) {
    return { no, disc, quantity, cost, amount };
}

const tire = [
    createData("1", <Typography variant="body2">Tire Change</Typography>, 1, "$0.00", "$0.00"),
    createData("2", <Typography variant="body2">Tire Change</Typography>, 1, "$0.00", "$0.00"),
];
const supply = [
    createData("1", <Typography variant="body2">Item Name 1</Typography>, 1, "$0.00", "$0.00"),
    createData("2", <Typography variant="body2">Item Name 2</Typography>, 1, "$0.00", "$0.00"),
    createData("2", <Typography variant="body2">Item Name 3</Typography>, 1, "$0.00", "$0.00"),
];

const rows = [
    createData("1", <Typography variant="body2">Labor</Typography>, 1, "$0.00", "$0.00"),
    createData(
        "2",
        <Box>
            <Typography variant="body2" style={{ fontSize: "14px" }}>
                Brakes
            </Typography>
            <Typography variant="body2" style={{ fontSize: "12px" }}>
                Part Provider: OEM
            </Typography>
            <Typography variant="body2" fontWeight={"bold"} style={{ fontSize: "12px", fontWeight: "400" }}>
                Part Number: 000000
            </Typography>
            <Typography variant="body1" style={{ fontSize: "14px", color: "#848484" }}>
                Optional Description: Morem ipsum dolor sit amet, consectetur elit. Morem ipsum dolor sit amet, consectetur elit. Morem ipsum dolor sit amet,
                consectetur elit. Morem ipsum dolor sit amet, consectetur elit.
            </Typography>
        </Box>,
        "2",
        "$000.00",
        "$000.00"
    ),
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
];

const Drafts = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [invoiceData, setInvoiceData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    }

    const invoiceDetailsData = async () => {
        setShowLoader(true);
        let data = await dispatch(invoiceDetails(`${id}`));
        if (data) {
            setInvoiceData(data);
            setShowLoader(false);
        }
    };
    useEffect(() => {
        invoiceDetailsData(`${id}`);
    }, []);

    const handlePrintPdf = () => {
        // Assuming invoicedata.pdfurl contains the URL of the PDF
        const pdfUrl = invoiceData?.pdfUrl;

        // Open a new window or tab to print the PDF
        window.open(pdfUrl, "_blank");

        // Close the menu
        // handleClose1();
    };
    const handleViewPdf = () => {
        // Assuming invoicedata.pdfurl contains the URL of the PDF
        const pdfUrl = invoiceData?.pdfUrl;

        // Open a new window or tab to print the PDF
        window.open(pdfUrl);

        // Close the menu
        // handleClose1();
    };

    const deleteDraftInvoice = async () => {
        let data = await dispatch(deleteInvoice(id));
        if (data) {
            navigate("/invoice/drafts");
        }
    };

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };

    if (showLoader) {
        return <Loader showLoader={showLoader} />;
    }

    // const shopData = localStorage.getItem("apex-saas_details");
    // const shopData = invoiceData?.shop;
    // const shopPhoneNumber = invoiceData?.shop?.phoneNumber;

    // const shopName = shopData ? JSON.parse(shopData)?.shopName : null;
    // const shopAddress = shopData ? JSON.parse(shopData)?.address : null;
    // const shopNumber = shopPhoneNumber ? JSON.parse(shopPhoneNumber) : null;

    return (
        <Box>
            <Box
                padding={"32px 24px"}
                sx={{
                    bgcolor: "background.main",
                    width: "fit-content",
                    minWidth: "100%",
                }}
            >
                {" "}
                <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    to="/invoice/all"
                                    style={{
                                        color: "black",
                                        opacity: "0.6",
                                        textDecoration: "none",
                                    }}
                                >
                                    Invoices
                                </Link>
                                <Link
                                    to="/invoice/drafts"
                                    style={{
                                        color: "black",
                                        opacity: "0.6",
                                        textDecoration: "none",
                                    }}
                                >
                                    Drafts
                                </Link>
                                <Link to="/invoice/drafts" style={{ color: "black", textDecoration: "none" }}>
                                    {invoiceData?.invoiceNumber}
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h4" sx={{ fontWeight: "bold", fontWeight: "500" }}>
                            {invoiceData?.invoiceNumber}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="end">
                    <Grid item>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<Edit />}
                                onClick={() => {
                                    navigate(`/invoice/edit/${id}`);
                                }}
                                className="btn-text-size-manage"
                            >
                                Edit INVOICE
                            </Button>
                            {/* <Button variant="contained" onClick={handleOpen}>more actions</Button> */}
                            <Button
                                variant="contained"
                                color="secondary"
                                id="demo-positioned-button"
                                aria-controls={open ? "demo-positioned-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick1}
                                className="btn-text-size-manage"
                            >
                                more actions
                            </Button>
                            <Menu
                                className="draft-menu-css"
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleClose1}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                {/* <MenuItem onClick={handleViewPdf}>
                  <RemoveRedEyeIcon style={{ marginRight: "10px" }} /> View PDF
                </MenuItem>
                <MenuItem onClick={handlePrintPdf}>
                  <DownloadIcon style={{ marginRight: "10px" }} /> Download PDF
                </MenuItem> */}
                                <MenuItem
                                    onClick={() => {
                                        deleteDraftInvoice();
                                        handleClose1();
                                    }}
                                >
                                    {" "}
                                    <DeleteIcon style={{ marginRight: "10px" }} /> Delete Invoice
                                </MenuItem>
                            </Menu>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container padding={"24px"} boxShadow={2} margin={"32px 0"} gap={2} sx={{ bgcolor: "white" }}>
                    <Grid container item spacing={2} justifyContent="space-between">
                        <Grid item style={{ color: "white" }} lg={12} md={12} sm={12} xs={12} xl={12}>
                            <Stack
                                direction={"row"}
                                spacing={2}
                                style={{
                                    backgroundColor: ESTIMATE_INVOICE_DRAFT,
                                    color: "black",
                                }}
                                alignItems={"center"}
                                padding={"8px 8px"}
                                borderRadius={"4px"}
                            >
                                <Stack>
                                    <InfoOutlined />
                                </Stack>
                                <Stack direction={"column"} spacing={0}>
                                    <Typography variant="title" sx={{ fontWeight: 500 }}>
                                        Draft
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} justifyContent="space-between">
                        <Grid item lg={7} md={12} sm={12} xs={12}>
                            <Typography variant="h3" fontWeight={400}>
                                Invoice{" "}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Stack spacing={0}>
                                <Typography variant="body1" fontWeight={"500"} fontSize={"24px"}>
                                    {invoiceData?.invoiceNumber}
                                </Typography>
                                <Typography variant="body1" fontWeight={400} lineHeight={2}>
                                    Invoice Prepared By: {invoiceData?.invoicePreparedBy}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    <Grid container item>
                        <Grid item lg={12} md={12} sm={6} xs={6}>
                            <Stack>
                                <Typography variant="h6">{invoiceData?.shop?.shopName}</Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {invoiceData?.shop?.address}
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {formatPhoneNumber(invoiceData?.shop?.phoneNumber)}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container item justifyItems={"space-between"}>
                        <Grid item lg={4} md={4} sm={4} xs={4} style={{ display: "flex", justifyContent: "start" }}>
                            <Stack>
                                <Typography variant="h6" fontWeight={"500"}>
                                    Dates
                                </Typography>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Dropped Off:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {invoiceData?.appointmentDroppedOffDate && moment(invoiceData?.appointmentDroppedOffDate).format("MMM D, YYYY")}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={2} style={{ width: "max-content" }}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Work Completion Date:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {invoiceData?.appointmentCompletionDate && moment(invoiceData?.appointmentCompletionDate).format("MMM D, YYYY")}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4} style={{ display: "flex", justifyContent: "center" }}>
                            <Stack>
                                <Typography variant="h6" fontWeight={500}>
                                    Customer Information
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {invoiceData?.customer?.fullName}
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {formatPhoneNumber(invoiceData?.customer?.phoneNumber)}
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {invoiceData?.customer?.email}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4} style={{ display: "flex", justifyContent: "end" }}>
                            <Stack>
                                <Typography variant="h6" fontWeight={500}>
                                    Vehicle Information
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {invoiceData?.vehicle?.year} {invoiceData?.vehicle?.vehicleMake?.name} {invoiceData?.vehicle?.vehicleModel?.name}{" "}
                                    {invoiceData?.vehicle?.vehicleModelTrim?.name}
                                </Typography>

                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight="bold">
                                        VIN:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {invoiceData?.vehicle?.vin}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Licence Plate Number:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {invoiceData?.vehicle?.licenseNumber && invoiceData?.vehicle?.licenseNumber !== ""
                                            ? invoiceData?.vehicle?.provinceCode + " " + invoiceData?.vehicle?.licenseNumber
                                            : ""}
                                        {/* {invoiceData?.vehicle?.provinceCode}{" "}
                    {invoiceData?.vehicle?.licenseNumber} */}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Odometer Dropped Off:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {FormatNumberForKm(invoiceData?.odoDroppedOff)} {invoiceData?.odoDroppedOff ? "km" : ""}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Odometer Returned:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {FormatNumberForKm(invoiceData?.odoReturned)} {invoiceData?.odoDroppedOff ? "km" : ""}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" fontWeight={"500"}>
                                Total Services: {invoiceData?.services?.length}
                            </Typography>
                        </Grid>
                        {invoiceData &&
                            invoiceData?.services?.length > 0 &&
                            invoiceData?.services?.map((item, index) => {
                                return (
                                    <>
                                        <Grid
                                            container
                                            item
                                            /*style={{
                          backgroundColor: "#c5d0da",
                          color: "black",
                          marginTop: "20px",
                        }}*/
                                            sx={{
                                                bgcolor: "secondary.main",
                                                color: "white",
                                                marginTop: "20px",
                                                borderTopLeftRadius: "4px",
                                                borderTopRightRadius: "4px",
                                            }}
                                        >
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Typography variant="h6" fontWeight={"500"} padding={"16px"}>
                                                    {item?.serviceName}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                                                {/* shop services table start */}
                                                <TableContainer style={{ minWidth: "850px" }}>
                                                    <Table aria-label="caption table" padding="16px">
                                                        <TableHead
                                                            style={{
                                                                backgroundColor: "#f4f6f8",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            <TableRow style={{ fontWeight: "bold" }}>
                                                                <TableCell width={"120px"}>No.</TableCell>
                                                                <TableCell> Description</TableCell>
                                                                <TableCell width={"120px"}> Quantity</TableCell>
                                                                <TableCell width={"120px"}> Cost</TableCell>
                                                                <TableCell width={"120px"} align="left">
                                                                    {" "}
                                                                    Amount
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {item?.labor && item?.labor?.qty > 0 ? (
                                                                <TableRow key={item?.labor?.qty}>
                                                                    <TableCell component="th" scope="row">
                                                                        1
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Typography variant="body2">Labor</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="left" width={"120px"}>
                                                                        {item?.labor?.qty}
                                                                    </TableCell>
                                                                    <TableCell align="left" width={"120px"}>
                                                                        <FormatNumber inputValue={item?.labor?.ratePerHour} />
                                                                    </TableCell>
                                                                    <TableCell align="left" style={{ paddingRight: "23px" }}>
                                                                        <FormatTotalNumbers inputValue={item?.labor?.totalAmount} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) : (
                                                                <TableRow key={item?.labor?.qty}>
                                                                    <TableCell component="th" scope="row">
                                                                        1
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Typography variant="body2">{item?.serviceName}</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="left" width={"120px"}>
                                                                        1
                                                                    </TableCell>
                                                                    <TableCell align="left" width={"120px"}>
                                                                        <FormatNumber inputValue={item?.fixedPrice} />
                                                                    </TableCell>
                                                                    <TableCell align="left" style={{ paddingRight: "23px" }}>
                                                                        <FormatTotalNumbers inputValue={item?.fixedPrice} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}

                                                            {item?.parts &&
                                                                item?.parts?.length > 0 &&
                                                                item?.parts?.map((row, index) => {
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <TableCell component="th" scope="row">
                                                                                {index + 1}
                                                                            </TableCell>
                                                                            <TableCell align="left">
                                                                                <Box>
                                                                                    <Typography variant="body2" fontWeight={"400"}>
                                                                                        {row?.partName}
                                                                                    </Typography>
                                                                                    <Typography variant="body2" fontWeight={"400"} style={{ fontSize: "12px" }}>
                                                                                        Part Provider:
                                                                                        {customisePartName(row?.partsProvider)}
                                                                                        {/* {(row?.partsProvider).toUpperCase()} */}
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        fontWeight={"400"}
                                                                                        style={{
                                                                                            fontSize: "12px",
                                                                                        }}
                                                                                    >
                                                                                        Part Number: {row?.partNumber}
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            color: "#848484",
                                                                                        }}
                                                                                    >
                                                                                        {row?.description}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                {row?.qty}
                                                                            </TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                <FormatNumber inputValue={row?.cost} />
                                                                            </TableCell>
                                                                            <TableCell align="left" style={{ paddingRight: "23px" }}>
                                                                                <FormatTotalNumbers inputValue={row?.totalAmount} />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {/* shop services table ends */}
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            })}
                        {invoiceData &&
                            invoiceData?.shopSupplies?.length > 0 &&
                            invoiceData?.shopSupplies?.map((item, index) => {
                                return (
                                    <>
                                        <Grid container item>
                                            {/* shop supplies table starts  */}
                                            <Grid
                                                container
                                                item
                                                sx={{
                                                    bgcolor: "secondary.main",
                                                    color: "white",
                                                    marginTop: "20px",
                                                    borderTopLeftRadius: "4px",
                                                    borderTopRightRadius: "4px",
                                                }}
                                                /*style={{
                            backgroundColor: "#c5d0da",
                            color: "black",
                            marginTop: "20px",
                          }}*/
                                            >
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Typography variant="h6" fontWeight={"500"} padding={"16px"}>
                                                        Shop Supplies
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item>
                                                <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                                                    <TableContainer style={{ minWidth: 800 }}>
                                                        <Table
                                                            aria-label="caption table"
                                                            padding="16px"
                                                            // style={{ boxShadow: 2 }}
                                                        >
                                                            <TableHead
                                                                style={{
                                                                    backgroundColor: "#f4f6f8",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                <TableRow>
                                                                    <TableCell width={"120px"}>No.</TableCell>
                                                                    <TableCell width={"600px"}> Description</TableCell>
                                                                    <TableCell width={"120px"}> Quantity</TableCell>
                                                                    <TableCell width={"120px"}> Cost</TableCell>
                                                                    <TableCell width={"120px"} align="left">
                                                                        {" "}
                                                                        Amount
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {invoiceData &&
                                                                    invoiceData?.shopSupplies?.map((row, index) => (
                                                                        <TableRow key={row?._id}>
                                                                            <TableCell component="th" scope="row" width={"120px"}>
                                                                                {index + 1}
                                                                            </TableCell>
                                                                            <TableCell align="left">{row?.itemName}</TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                {row?.qty}
                                                                            </TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                <FormatNumber inputValue={row?.cost} />
                                                                            </TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                <FormatNumber inputValue={row?.totalAmount} />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                            {/* shop supplies table ends  */}
                                        </Grid>
                                    </>
                                );
                            })}

                        <Grid container item justifyContent={"end"} padding={"80px 23px 24px 24px"}>
                            <Grid item>
                                <Stack direction="row" spacing={2}>
                                    <Box textAlign={"end"}>
                                        <Typography mb={1}>Sub Total</Typography>
                                        {invoiceData?.discount > 0 && (
                                            <Typography mb={1}>
                                                {invoiceData?.membershipProgram?.programName
                                                    ? `Discount - ${invoiceData?.membershipProgram?.programName} (${invoiceData?.membershipProgram?.discount}%)`
                                                    : "Discount"}
                                            </Typography>
                                        )}
                                        <Typography mb={1}>Tax</Typography>
                                        <Typography fontWeight={500}>Total</Typography>
                                    </Box>
                                    <Box textAlign={"end"}>
                                        <Typography mb={1}>
                                            <FormatTotalNumbers inputValue={invoiceData?.subTotal} />
                                        </Typography>
                                        {invoiceData?.discount > 0 && (
                                            <Typography mb={1}>
                                                <FormatTotalNumbers inputValue={invoiceData?.discount} />
                                            </Typography>
                                        )}
                                        <Typography mb={1}>
                                            <FormatTotalNumbers inputValue={invoiceData?.tax} />
                                        </Typography>
                                        <Typography>
                                            <FormatTotalNumbers inputValue={invoiceData?.Total} />
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={"start"} marginTop={"32px"}>
                            <Grid item>
                                <Stack spacing={1}>
                                    <Typography variant="body1" fontWeight={"500"}>
                                        Terms and Conditions
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} maxWidth={"500px"} style={{ color: "#848484" }}>
                                        {invoiceData?.termsAndWarranty?.title}
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} maxWidth={"500px"} style={{ color: "#848484" }}>
                                        {invoiceData?.termsAndWarranty?.description}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog> */}
        </Box>
    );
};

export default Drafts;
