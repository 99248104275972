import { CheckCircleOutlined } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ESTIMATE_INVOICE_SENT } from "../../../App";
import FormatNumber, { FormatNumberForKm, FormatTotalNumbers } from "../../../components/FormatNumber/FormatNumber";
import { invoiceDetails, sendInvoice } from "../../../redux/Invoice/action";
import { formatPhoneNumber } from "../../Common/FormatPhoneNumber";
import Loader from "../../Common/Loader";
import SuccessMessageModal from "../../Common/SuccessMessage";
import "./send.css";
import { customisePartName } from "../../../utility/helpers/displayHelper";

function createData(no, disc, quantity, cost, amount) {
    return { no, disc, quantity, cost, amount };
}

const Sent = () => {
    const navigate = useNavigate();
    const [invoiceData, setInvoiceData] = React.useState();
    const [showLoader, setShowLoader] = React.useState(false);
    const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");

    const dispatch = useDispatch();
    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    }

    const { id } = useParams();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = React.useState(false);

    const invoiceDetailsData = async () => {
        setShowLoader(true);
        let data = await dispatch(invoiceDetails(`${id}`));
        if (data) {
            setInvoiceData(data);
            setShowLoader(false);
        }
    };
    useEffect(() => {
        invoiceDetailsData(`${id}`);
    }, []);

    const handlePrintPdf = () => {
        // Assuming invoicedata.pdfurl contains the URL of the PDF
        const pdfUrl = invoiceData?.pdfUrl;

        // Open a new window or tab to print the PDF
        window.open(pdfUrl, "_blank");

        // Close the menu
        handleClose1();
    };

    const clickDownload = async () => {
        try {
            // Fetch the file content
            const fileResponse = await fetch(invoiceData?.pdfUrl);
            const fileBlob = await fileResponse.blob();

            // Create a download link
            const url = window.URL.createObjectURL(fileBlob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "downloaded_file.pdf");

            // Append the link to the body, trigger a click, and remove the link
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release the object URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };
    const handleSend = async () => {
        setShowLoader(true);
        const data = await dispatch(sendInvoice(id));
        if (data) {
            setOpenSuccessModal(true);
            setSuccessMessage("Invoice Pdf Send to Customer Successfully");
            handleClose1();
        }
        setShowLoader(false);
    };
    if (showLoader) {
        return <Loader showLoader={showLoader} />;
    }

    return (
        <Box>
            <Box
                padding={"32px 24px"}
                sx={{
                    bgcolor: "background.main",
                    width: "fit-content",
                    minWidth: "100%",
                }}
            >
                {" "}
                <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    to="/invoice/all"
                                    style={{
                                        color: "black",
                                        opacity: "0.6",
                                        textDecoration: "none",
                                    }}
                                >
                                    Invoices
                                </Link>
                                <Link
                                    to="/invoice/sents"
                                    style={{
                                        color: "black",
                                        opacity: "0.6",
                                        textDecoration: "none",
                                    }}
                                >
                                    Sent
                                </Link>
                                <Link to={`/invoice/sent/${id}`} style={{ color: "black", textDecoration: "none" }}>
                                    {invoiceData?.invoiceNumber}
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h4" sx={{ fontWeight: "500" }}>
                            {invoiceData?.invoiceNumber}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="end">
                    <Grid item>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="btn-text-size-manage"
                                startIcon={<VisibilityIcon />}
                                onClick={handlePrintPdf}
                            >
                                View PDF INVOICE
                            </Button>
                            {/* <Button
                variant="contained"
                color="secondary"
                className="btn-text-size-manage"
                startIcon={<DownloadIcon />}
                onClick={clickDownload}
                // onClick={handleDownload}
              >
                Download PDF
              </Button> */}
                            {/* Changing as per client feedback notion point Saas V1-182 */}
                            <Button
                                variant="contained"
                                color="secondary"
                                id="demo-positioned-button"
                                aria-controls={open1 ? "demo-positioned-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? "true" : undefined}
                                onClick={handleClick1}
                                size="large"
                            >
                                more actions
                            </Button>
                            <Menu id="demo-positioned-menu" aria-labelledby="demo-positioned-button" anchorEl={anchorEl} open={open1} onClose={handleClose1}>
                                <MenuItem onClick={clickDownload}>
                                    <DownloadIcon style={{ marginRight: "10px" }} /> Download PDF
                                </MenuItem>
                                <MenuItem onClick={handleSend}>
                                    <SendIcon style={{ marginRight: "10px" }} />
                                    Send to Customer
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        navigate(`/customers/${invoiceData?.customer?._id}/${invoiceData?.customer?.createdFrom}`);
                                    }}
                                >
                                    <VisibilityIcon style={{ marginRight: "10px" }} />
                                    View Customer
                                </MenuItem>
                            </Menu>
                            {/* <Button variant="contained" onClick={handleOpen}>more actions</Button> */}
                            {/* <Button
                variant="contained"
                color="secondary"
                id="demo-positioned-button"
                aria-controls={open1 ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
                className="btn-text-size-manage"
              >
                more actions
              </Button> */}
                            {/* <Menu
                style={{ width: "200px" }}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                className="dropdown-position"
              >
                {/* <MenuItem onClick={handleViewPdf}>
                  <RemoveRedEyeIcon style={{ marginRight: "10px" }} /> View PDF
                </MenuItem> */}
                            {/* <MenuItem onClick={handlePrintPdf}>
                <DownloadIcon style={{ marginRight: "10px" }} /> Download PDF
              </MenuItem> */}
                            {/* <MenuItem onClick={handleClose1}>
                  {" "}
                  <DeleteIcon style={{ marginRight: "10px" }} /> Delete Invoice
                </MenuItem> */}
                            {/* </Menu> */}
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container padding={"24px"} margin={"32px 0"} boxShadow={2} gap={2} sx={{ bgcolor: "white" }}>
                    <Grid container item spacing={2} justifyContent="space-between">
                        <Grid item style={{ color: "white" }} lg={12} md={12} sm={12} xs={12} xl={12}>
                            <Stack
                                direction={"row"}
                                spacing={2}
                                style={{
                                    backgroundColor: ESTIMATE_INVOICE_SENT,
                                    color: "black",
                                }}
                                alignItems={"center"}
                                padding={"8px 8px"}
                                borderRadius={"4px"}
                            >
                                <Stack>
                                    <CheckCircleOutlined />
                                </Stack>
                                <Stack direction={"column"} spacing={0}>
                                    <Typography variant="title" sx={{ fontWeight: 500 }}>
                                        Sent
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} justifyContent="space-between">
                        <Grid item lg={7} md={12} sm={12} xs={12}>
                            <Typography variant="h3" fontWeight={400}>
                                Invoice{" "}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Stack spacing={0}>
                                <Typography variant="body1" fontWeight={"500"} fontSize={"24px"}>
                                    {invoiceData?.invoiceNumber}
                                </Typography>
                                <Typography variant="body1" fontWeight={400} lineHeight={2} className="less-space">
                                    Invoice Prepared By: {invoiceData?.invoicePreparedBy}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    <Grid container item>
                        <Grid item>
                            <Stack>
                                <Typography variant="h6">{invoiceData?.shop?.shopName}</Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {invoiceData?.shop?.address}
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {formatPhoneNumber(invoiceData?.shop?.phoneNumber)}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container item justifyItems={"space-between"}>
                        <Grid item lg={4} md={4} sm={4} xs={4} style={{ display: "flex", justifyContent: "start" }}>
                            <Stack>
                                <Typography variant="h6" fontWeight={"500"}>
                                    Dates
                                </Typography>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Dropped Off:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {moment(invoiceData?.appointmentDroppedOffDate).format("MMM D, YYYY")}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={2} style={{ width: "max-content" }}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Work Completion Date:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {moment(invoiceData?.appointmentCompletionDate).format("MMM D, YYYY")}
                                        {/* {invoiceData?.appointmentCompletionDate} */}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4} style={{ display: "flex", justifyContent: "center" }}>
                            <Stack>
                                <Typography variant="h6" fontWeight={500}>
                                    Customer Information
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {invoiceData?.customer?.fullName}
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {formatPhoneNumber(invoiceData?.customer?.phoneNumber)}
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {invoiceData?.customer?.email}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4} style={{ display: "flex", justifyContent: "end" }}>
                            <Stack>
                                <Typography variant="h6" fontWeight={500}>
                                    Vehicle Information
                                </Typography>
                                <Typography variant="body1" fontWeight={400}>
                                    {invoiceData?.vehicle?.year} {invoiceData?.vehicle?.vehicleMake?.name} {invoiceData?.vehicle?.vehicleModel?.name}{" "}
                                    {invoiceData?.vehicle?.vehicleModelTrim?.name}{" "}
                                </Typography>

                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight="500">
                                        VIN:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {invoiceData?.vehicle?.vin}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight="500">
                                        Licence Plate Number:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {invoiceData?.vehicle?.licenseNumber && invoiceData?.vehicle?.licenseNumber !== ""
                                            ? invoiceData?.vehicle?.provinceCode + " " + invoiceData?.vehicle?.licenseNumber
                                            : ""}
                                        {/* {invoiceData?.vehicle?.provinceCode}{" "}
                    {invoiceData?.vehicle?.licenseNumber} */}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight="500">
                                        Odometer Dropped Off:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {FormatNumberForKm(invoiceData?.odoDroppedOff)} {invoiceData?.odoDroppedOff ? "km" : ""}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="body1" fontWeight="500">
                                        Odometer Returned:
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} className="less-space">
                                        {FormatNumberForKm(invoiceData?.odoReturned)} {invoiceData?.odoDroppedOff ? "km" : ""}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" fontWeight={"500"}>
                                Total Services: {invoiceData?.services?.length}
                            </Typography>
                        </Grid>

                        {invoiceData &&
                            invoiceData?.services?.length > 0 &&
                            invoiceData?.services?.map((item, index) => {
                                return (
                                    <>
                                        <Grid
                                            container
                                            item
                                            sx={{
                                                bgcolor: "secondary.main",
                                                color: "white",
                                                marginTop: "20px",
                                                borderTopLeftRadius: "4px",
                                                borderTopRightRadius: "4px",
                                            }}
                                            /*style={{
                          backgroundColor: "#c5d0da",
                          color: "black",
                          marginTop: "20px",
                        }}*/
                                        >
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Typography variant="h6" fontWeight={"500"} padding={"16px"}>
                                                    {item?.serviceName}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                                                {/* shop services table start */}
                                                <TableContainer style={{ minWidth: "850px", overflow: "hidden" }}>
                                                    <Table aria-label="caption table" padding="16px">
                                                        <TableHead
                                                            style={{
                                                                backgroundColor: "#f4f6f8",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            <TableRow style={{ fontWeight: "bold" }}>
                                                                <TableCell width={"120px"}>No.</TableCell>
                                                                <TableCell> Description</TableCell>
                                                                <TableCell width={"120px"}> Quantity</TableCell>
                                                                <TableCell width={"120px"}> Cost</TableCell>
                                                                <TableCell width={"120px"} align="left">
                                                                    {" "}
                                                                    Amount
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {item?.labor && item?.labor?.qty > 0 ? (
                                                                <TableRow key={item?.labor?.qty}>
                                                                    <TableCell component="th" scope="row" width={"120px"}>
                                                                        1
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Typography variant="body2">Labor</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="left" width={"120px"}>
                                                                        {item?.labor?.qty}
                                                                    </TableCell>
                                                                    <TableCell align="left" width={"120px"}>
                                                                        <FormatNumber inputValue={item?.labor?.ratePerHour} />
                                                                    </TableCell>
                                                                    <TableCell align="left" style={{ paddingRight: "23px" }}>
                                                                        <FormatTotalNumbers inputValue={item?.labor?.totalAmount} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) : (
                                                                <TableRow key={item?.labor?.qty} width={"120px"}>
                                                                    <TableCell component="th" scope="row">
                                                                        1
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Typography variant="body2">{item?.serviceName}</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="left" width={"120px"}>
                                                                        1
                                                                    </TableCell>
                                                                    <TableCell align="left" width={"120px"}>
                                                                        <FormatNumber inputValue={item?.fixedPrice} />
                                                                    </TableCell>
                                                                    <TableCell align="left" style={{ paddingRight: "23px" }}>
                                                                        <FormatTotalNumbers inputValue={item?.fixedPrice} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                            {item?.parts &&
                                                                item?.parts?.length > 0 &&
                                                                item?.parts?.map((row, index) => {
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <TableCell component="th" scope="row" width={"120px"}>
                                                                                {index + 1}
                                                                            </TableCell>
                                                                            <TableCell align="left">
                                                                                <Box>
                                                                                    <Typography variant="body2" fontWeight={"400"}>
                                                                                        {row?.partName}
                                                                                    </Typography>
                                                                                    <Typography variant="body2" fontWeight={"400"} style={{ fontSize: "12px" }}>
                                                                                        Part Provider:
                                                                                        {customisePartName(row?.partsProvider)}
                                                                                        {/* {(row?.partsProvider).toUpperCase()} */}
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        fontWeight={"400"}
                                                                                        style={{
                                                                                            fontSize: "12px",
                                                                                        }}
                                                                                    >
                                                                                        Part Number: {row?.partNumber}
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            color: "#848484",
                                                                                        }}
                                                                                    >
                                                                                        {row?.description}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                {row?.qty}
                                                                            </TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                <FormatNumber inputValue={row?.cost} />
                                                                            </TableCell>
                                                                            <TableCell align="left" style={{ paddingRight: "23px" }}>
                                                                                <FormatTotalNumbers inputValue={row?.totalAmount} />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {/* shop services table ends */}
                                            </Grid>
                                            {/* /}
                        <Grid container justifyContent={"end"} marginTop={"24px"}>
                          <Grid item>
                            <Button
                              variant="contained"
                              startIcon={<Add />}
                              onClick={handleOpen}
                              className="btn-text-size-manage"
                            >
                              {" "}
                              ADD SHOP SUPPLIES
                            </Button>
                            <AddShopSupplyModal open={open} handleClose={handleClose} />
                          </Grid>
                        </Grid>
                        {/* shop supplies table starts  */}

                                            {/* shop supplies table ends  */}
                                        </Grid>
                                    </>
                                );
                            })}
                        {invoiceData &&
                            invoiceData?.shopSupplies?.length > 0 &&
                            invoiceData?.shopSupplies?.map((item, index) => {
                                return (
                                    <>
                                        <Grid container item>
                                            {/* shop supplies table starts  */}
                                            <Grid
                                                container
                                                item
                                                sx={{
                                                    bgcolor: "secondary.main",
                                                    color: "white",
                                                    marginTop: "20px",
                                                    borderTopLeftRadius: "4px",
                                                    borderTopRightRadius: "4px",
                                                }}
                                                /*style={{
                            backgroundColor: "#c5d0da",
                            color: "black",
                            marginTop: "20px",
                          }}*/
                                            >
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Typography variant="h6" fontWeight={"500"} padding={"16px"}>
                                                        Shop Supplies
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item>
                                                <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                                                    <TableContainer style={{ minWidth: 800, overflow: "hidden" }}>
                                                        <Table
                                                            aria-label="caption table"
                                                            padding="16px"
                                                            // style={{ boxShadow: 2 }}
                                                        >
                                                            <TableHead
                                                                style={{
                                                                    backgroundColor: "#f4f6f8",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                <TableRow>
                                                                    <TableCell width={"120px"}>No.</TableCell>
                                                                    <TableCell> Description</TableCell>
                                                                    <TableCell width={"120px"}> Quantity</TableCell>
                                                                    <TableCell width={"120px"}> Cost</TableCell>
                                                                    <TableCell width={"120px"} align="left">
                                                                        {" "}
                                                                        Amount
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {invoiceData &&
                                                                    invoiceData?.shopSupplies?.map((row, index) => (
                                                                        <TableRow key={row?._id}>
                                                                            <TableCell component="th" scope="row" width={"120px"}>
                                                                                {index + 1}
                                                                            </TableCell>
                                                                            <TableCell align="left">{row?.itemName}</TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                {row?.qty}
                                                                            </TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                <FormatNumber inputValue={row?.cost} />
                                                                            </TableCell>
                                                                            <TableCell align="left" width={"120px"}>
                                                                                <FormatNumber inputValue={row?.totalAmount} />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                            {/* shop supplies table ends  */}
                                        </Grid>
                                    </>
                                );
                            })}

                        <Grid container item justifyContent={"end"} padding={"80px 23px 24px 24px"}>
                            <Grid item>
                                <Stack direction="row" spacing={2}>
                                    <Box textAlign={"end"}>
                                        <Typography mb={1}>Sub Total</Typography>
                                        {invoiceData?.discount > 0 && (
                                            <Typography mb={1}>
                                                {invoiceData?.membershipProgram?.programName
                                                    ? `Discount - ${invoiceData?.membershipProgram?.programName} (${invoiceData?.membershipProgram?.discount}%)`
                                                    : "Discount"}
                                            </Typography>
                                        )}
                                        <Typography mb={1}>Tax</Typography>
                                        <Typography>Total</Typography>
                                    </Box>
                                    <Box textAlign={"end"}>
                                        <Typography mb={1}>
                                            <FormatTotalNumbers inputValue={invoiceData?.subTotal} />
                                        </Typography>

                                        {invoiceData?.discount > 0 && (
                                            <Typography mb={1}>
                                                <FormatTotalNumbers inputValue={invoiceData?.discount} />
                                            </Typography>
                                        )}

                                        <Typography mb={1}>
                                            <FormatTotalNumbers inputValue={invoiceData?.tax} />
                                        </Typography>
                                        <Typography>
                                            <FormatTotalNumbers inputValue={invoiceData?.Total} />
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={"start"} marginTop={"32px"}>
                            <Grid item>
                                <Stack spacing={1}>
                                    <Typography variant="body1" fontWeight={"400"}>
                                        Terms and Conditions
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} maxWidth={"500px"} style={{ color: "#848484" }}>
                                        {invoiceData?.termsAndWarranty?.title}
                                    </Typography>
                                    <Typography variant="body1" fontWeight={400} maxWidth={"500px"} style={{ color: "#848484" }}>
                                        {invoiceData?.termsAndWarranty?.description}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog> */}
            <SuccessMessageModal message={successMessage} open={openSuccessModal} setOpenSuccessModal={setOpenSuccessModal} />
        </Box>
    );
};

export default Sent;
